<template>

<section >
    <b-row>
        <b-col cols="12" sm="12" md="3" lg="4" xl="4"> 
        </b-col>

        <b-col cols="12" sm="12" md="6" lg="4" xl="4" > 
    
            

          <div style="justify-content: space-between;display: flex"> 

            <h4 class="font-weight-bolder" style="text-align: left !important;margin-bottom: 15px;">
              <feather-icon
                        
                        icon="UsersIcon"
                        size="16"
                        
                      
                        />  Solicitudes de empleados
            </h4>


            <feather-icon
                        icon="RefreshCcwIcon"
                        size="16"
                        @click="refresh"
                        style="cursor:pointer"
                        />
            </div>
                <div v-if="loading">
                  
                  <b-row  class="sesiones">
                      
                    <b-col  cols="2" sm="2" md="2" xl="2" lg="2" style="margin-top:10px; display:flex">
                        <b-skeleton style="margin:auto" type="avatar" width="40px"  height="40px"></b-skeleton>

                      </b-col>

                      <b-col  cols="10" sm="10" md="10" xl="10" lg="10" style="padding:0px;margin-top:10px">

                        <b-row>

                          <b-col  cols="12" sm="12" md="12" xl="12" lg="12">
                            <b-skeleton width="100%"  height="10px"></b-skeleton>
                            <b-skeleton width="40%"  height="10px"></b-skeleton>
                          </b-col>

                    


                        <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
                          <b-skeleton width="100%"  height="40px" style="margin-bottom:0px"></b-skeleton>
                          </b-col>

                          <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
                          <b-skeleton width="100%"  height="40px" style="margin-bottom:0px"></b-skeleton>
                          </b-col>

                        </b-row>

                      </b-col>
                      
                      </b-row>

                  <b-row  class="sesiones">
                      
                    <b-col  cols="2" sm="2" md="2" xl="2" lg="2" style="margin-top:10px; display:flex">
                        <b-skeleton style="margin:auto" type="avatar" width="40px"  height="40px"></b-skeleton>

                      </b-col>

                      <b-col  cols="10" sm="10" md="10" xl="10" lg="10" style="padding:0px;margin-top:10px">

                        <b-row>

                          <b-col  cols="12" sm="12" md="12" xl="12" lg="12">
                            <b-skeleton width="100%"  height="10px"></b-skeleton>
                            <b-skeleton width="40%"  height="10px"></b-skeleton>
                          </b-col>

                    


                        <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
                          <b-skeleton width="100%"  height="40px" style="margin-bottom:0px"></b-skeleton>
                          </b-col>

                          <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
                          <b-skeleton width="100%"  height="40px" style="margin-bottom:0px"></b-skeleton>
                          </b-col>

                        </b-row>

                      </b-col>

                  </b-row>

                  <b-row  class="sesiones">
                    
                    <b-col  cols="2" sm="2" md="2" xl="2" lg="2" style="margin-top:10px; display:flex">
                        <b-skeleton style="margin:auto" type="avatar" width="40px"  height="40px"></b-skeleton>

                      </b-col>

                      <b-col  cols="10" sm="10" md="10" xl="10" lg="10" style="padding:0px;margin-top:10px">

                        <b-row>

                          <b-col  cols="12" sm="12" md="12" xl="12" lg="12">
                            <b-skeleton width="100%"  height="10px"></b-skeleton>
                            <b-skeleton width="40%"  height="10px"></b-skeleton>
                          </b-col>

                    


                        <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
                          <b-skeleton width="100%"  height="40px" style="margin-bottom:0px"></b-skeleton>
                          </b-col>

                          <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
                          <b-skeleton width="100%"  height="40px" style="margin-bottom:0px"></b-skeleton>
                          </b-col>

                        </b-row>

                      </b-col>
                      
                    </b-row>


              </div>

              <b-row  v-else-if="codeSolicitudes200">
  

                  
                  <b-col
                  class="div_notificacion"
                  cols="12" sm="12" md="12" xl="12" lg="12"
                  v-for="solicitud in solicitudes" :key="solicitud.id"
                  >

                  <b-row>
                  <b-col  cols="2" sm="2" md="2" xl="2" lg="2" align="center" style="display:flex" >


                                  <b-avatar
                                  style="margin:auto"
                                                  size="45"
                                                  :src="solicitud.photoEmpleado"
                                                  variant="light-primary"
                                                  
                                                  >
                                  
                                                  </b-avatar>

                              
                          </b-col>

                          <b-col  cols="10" sm="10" md="10" xl="10" lg="10" style="padding:0px;margin-top:0px; margin-bottom:10px">

                              <b-row>

                              <b-col  cols="12" sm="12" md="12" xl="12" lg="12">
                                  <p style="margin:0px;"><b>{{ solicitud.displayNameEmpleado }}</b> te envió una solicitud para formar parte de tu negocio <b>{{ solicitud.name }}</b></p>
                                  <p style="margin:0px; font-size:10px;">{{ solicitud.dateFormated }}</p>
                              
                              </b-col>

                              


                              <b-col  cols="6" sm="6" md="6" xl="6" lg="6" style="margin-top:5px">

                              <b-button 
                                                                  @click="confirmar(solicitud.id, solicitud.displayNameEmpleado, solicitud.photoEmpleado)"
                                                                  variant="primary"
                                                                  size="sm"
                                                                  block
                                                      
                                                                  >
                                                              

                                                                      Confirmar
                                                                  

                                                                  </b-button>
                              
                              
                              </b-col>

                              <b-col  cols="6" sm="6" md="6" xl="6" lg="6" style="margin-top:5px">

                              <b-button 
                                                                  @click="eliminar(solicitud.displayNameEmpleado,solicitud.id)"
                                                                  variant="danger"
                                                                  size="sm"
                                                                  block
                                                                 
                                                                  >
                                                              

                                                                      Eliminar
                                                                  

                                                                  </b-button>

                                      </b-col>

                                                                
                              </b-row>
                                                            
                              </b-col>
                            
                          </b-row>

                </b-col>


                </b-row>

                <div v-else-if="emptyNotificaciones" align="center" style="margin-top: 15px;">
                  
                  <svg  class="icons_colors"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 480.61 480.61" ><g><path d="M469.012 20.343l-2.395-6.339-6.339-2.394C439.878 3.906 418.436.0 396.547.0c-48.104.0-93.33 18.733-127.346 52.749l-48.227 48.227-107.787 5.228-90.214 90.213 77.783 24.777-28.763 28.762 16.802 16.802-37.224 57.056 105.235 105.237 57.057-37.224 16.802 16.802 28.763-28.763 24.775 77.783 90.216-90.214 5.227-107.786 48.227-48.227C477.832 161.462 493.98 86.459 469.012 20.343zM79.029 182.788l47.182-47.181 63.197-3.065-64.773 64.773L79.029 182.788zM160.805 390.623l-70.806-70.806 20.473-31.381 81.713 81.714L160.805 390.623zM345.015 354.412l-47.181 47.181-14.527-45.606 64.773-64.773L345.015 354.412zM230.665 366.202 114.419 249.956 290.414 73.961C318.764 45.612 356.455 30 396.547 30c15.957.0 31.625 2.487 46.671 7.401 17.451 53.48 3.463 112.785-36.558 152.807L230.665 366.202z"/><path d="M364.951 70.67c-12.02.0-23.32 4.681-31.819 13.18-17.546 17.545-17.546 46.094.0 63.64 8.499 8.499 19.8 13.18 31.819 13.18s23.32-4.681 31.819-13.18c17.546-17.545 17.546-46.094.0-63.64C388.272 75.351 376.971 70.67 364.951 70.67zM375.558 126.277c-2.833 2.833-6.6 4.394-10.606 4.394s-7.773-1.561-10.606-4.393c-5.849-5.849-5.849-15.365.0-21.214 2.833-2.833 6.6-4.393 10.606-4.393s7.773 1.56 10.606 4.393C381.406 110.912 381.406 120.429 375.558 126.277z"/><path d="M.013 375.612l33.999-34 21.213 21.213-33.999 33.999L.013 375.612zM105.004 480.61l-21.213-21.213 33.999-33.999 21.213 21.213L105.004 480.61zM25.031 432.878l50.122-50.122 21.213 21.213-50.122 50.122L25.031 432.878z"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>

                  <p class="errorTexto">   No tiene solicitudes de empleados  </p>

                </div>

                </b-col>

                     <b-col cols="12" sm="12" md="3" lg="4" xl="4"> 
        </b-col>
    </b-row>
   </section>
  
  </template>
  
  <script>
  import { BRow, BCol, BCard, BLink,BSkeleton,BMedia,BAvatar, BButton } from 'bootstrap-vue'
  export default {
    components: {
      BRow,
      BButton,
      BCol,
      BCard,
      BLink,
      BSkeleton,
      BMedia,
      BAvatar
    },
    data() {


  
      const datos_json = JSON.parse(this.$userGlobal);
  
     
  
      return {
        userId: datos_json.userId,
        tokenAuth: this.$tokenGlobal,
        solicitudes:[],
        loading:true,
        emptyNotificaciones:false,
        codeSolicitudes200:false

      }
    },
    created() {
   
      document.title = 'EQCoop - Solicitudes de empleados';

  
    
    },
    activated() {

      this.$eventBus.$emit('reiniciarMenu1')
          this.$eventBus.$emit('reiniciarMenu2')
},
    mounted() {

      this.getSolicitudes();

      this.$eventBus.$on('reiniciarSolicitudesIndex', () => {

        this.getSolicitudes();


      })
  

  
    },
    methods: {

      eliminar(displayName,id){

        this.$eventBus.$emit('eliminarSolicitud',displayName, id );  

      },

      confirmar(id, displayNameEmpleado, photo){

this.$eventBus.$emit('confirmarSolicitud',id, displayNameEmpleado, photo );  

},

     

      refresh(){

        this.getSolicitudes();

      },

      getSolicitudes(){

   
        this.loading=true;
  

    this.$https.post('/locals/getSolicitudesAll/', { tokenAuth: this.$tokenGlobal, userId: this.userId }).then(response => {



    if (response.data.code == 200) {

      
      this.loading=false;

      this.solicitudes= response.data.solicitudes;

      if(Number(this.solicitudes.length) > Number("0")){

        this.codeSolicitudes200=true;
        this.emptyNotificaciones=false;
        }else{

        this.codeSolicitudes200=false;
        this.emptyNotificaciones=true;

        }



    } else {

    this.$toast.error(response.data.message, {
      position: 'top-right',
      timeout: 3010,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.4,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: true,
      rtl: false,
    })


    if (response.data.code == 401) {
    
    localStorage.removeItem('userData')


    // Redirect to login page
    this.$router.push({ name: 'auth-login' })
    } else {
    this.getSolicitudes();
    }
    }
    }).catch(error => {
    this.getSolicitudes();
    })

      }
  
 

  
    },
  }
  </script>
  
  <style lang="scss" >



  
  </style>
  